import * as React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    ButtonPropsColorOverrides,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { OverridableStringUnion } from '@mui/types'

export default function createActionDialog(
    onConfirm: any,
    onCancel: any,
    title: string,
    content: string | any,
    okButtonText = 'OK',
    cancelButtonText = 'ANNULLER',
    okButtonColor: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    > = 'secondary',
    cancelButtonColor: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    > = 'error',
    hideTitle = false,
    hideActions = false
) {
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false)
    const [loadingConfirm, setLoadingConfirm] = React.useState(false)
    const [loadingCancel, setLoadingCancel] = React.useState(false)

    async function handleCancel() {
        setLoadingCancel(true)
        await onCancel()
        setConfirmDialogOpen(false)
        setLoadingCancel(false)
    }

    async function handleConfirm() {
        setLoadingConfirm(true)
        await onConfirm()
        setConfirmDialogOpen(false)
        setLoadingConfirm(false)
    }

    return {
        openDialog: () => setConfirmDialogOpen(true),
        closeDialog: () => setConfirmDialogOpen(false),
        DialogComponent: (
            <Dialog fullScreen={false} open={confirmDialogOpen}>
                {!hideTitle && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                {!hideActions && (
                    <DialogActions>
                        <LoadingButton
                            loading={loadingCancel}
                            variant="outlined"
                            color={cancelButtonColor}
                            autoFocus
                            sx={{ textTransform: 'none' }}
                            onClick={handleCancel}
                        >
                            {cancelButtonText}
                        </LoadingButton>
                        <LoadingButton
                            loading={loadingConfirm}
                            variant="contained"
                            className="autotest-dialog-confirm-button"
                            color={okButtonColor}
                            sx={{ textTransform: 'none' }}
                            onClick={handleConfirm}
                            autoFocus
                        >
                            {okButtonText}
                        </LoadingButton>
                    </DialogActions>
                )}
            </Dialog>
        ),
    }
}
