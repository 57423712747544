import * as React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

import { SxProps, Theme, useTheme } from '@mui/material'
import { navigate } from 'gatsby'

export default function DynamicLoadingButton({
    onClick = undefined,
    text = '',
    children = undefined,
    disabled = false,
    variant = 'contained',
    sx = {},
    startIcon = undefined,
    endIcon = undefined,
    color = 'primary',
    size = undefined,
    textTransform = 'none',
    whiteSpace = 'nowrap',
    borderRadius = '4px',
    height = undefined, //'44px',
    type = undefined,
    loading = undefined,
    className = undefined,
    href = undefined,
    hrefNewWindow = false,
    margin = 'auto',
    my = 1,
    ...other
}: any & { sx: SxProps<Theme> }) {
    const theme = useTheme()
    const [loadingFromOnClick, setLoadingFromOnClick] = React.useState(false)
    const csx: SxProps<Theme> = {
        textTransform,
        whiteSpace,
        my,
        height,
        paddingLeft: size == null ? theme.spacing(3) : undefined,
        paddingRight: size == null ? theme.spacing(3) : undefined,
        margin: margin,
        padding: size == null ? '10px 18px' : undefined,
        borderRadius,
        // fontFamily: 'Outfit',
        fontWeight: 'bold',
        ...sx,
    }
    return (
        <LoadingButton
            {...other}
            type={type}
            loading={loading != null ? loading : loadingFromOnClick}
            color={color}
            variant={variant}
            disabled={disabled}
            startIcon={startIcon}
            size={size}
            endIcon={endIcon}
            href={href}
            className={className}
            sx={csx}
            onClick={async (event: React.MouseEvent<HTMLAnchorElement>) => {
                if (type === 'submit') {
                    return
                } else {
                    setLoadingFromOnClick(true)
                    try {
                        if (!!onClick) {
                            if (href) {
                                event.preventDefault()
                            }
                            await onClick(event)
                        } else if (!!href) {
                            event.preventDefault()
                            if (hrefNewWindow) {
                                window?.open(href, '_blank')
                            } else {
                                navigate(href)
                            }
                        }
                    } finally {
                        setLoadingFromOnClick(false)
                    }
                }
            }}
        >
            {children || text}
        </LoadingButton>
    )
}
