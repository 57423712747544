import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Badge, Box, Grid, Icon, IconButton, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import DynamicLoadingButton from '../../../../components/input/DynamicLoadingButton'
import dayjs from 'dayjs'
import { httpGet, httpPost } from '../../../../services/http'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'
import { userLocation } from '../../../../lib/interfaces'
import countryData from '../../../../lib/countries.json'
import { getUserTypeFunctions } from '../../../../lib/apiFunctions'
import { ComplianceReportsTypes } from '../../../../lib/enums'
import { useSessionIfExists } from '../../../../hooks/useAuth'
import { navigate } from 'gatsby'
import createAlertSnackbar from '../../../../components/modals/snackbars'
import { getLocation } from '../../../../services/geoLocation'
import { getDeviceInfo } from '../../../../utils/deviceInfo'
import { createSmsLocationHelperDialog } from '../../../../components/modals/SmsLocationHelperialog'

export default function (props: any) {
    const [locationData, setLocationData] = React.useState<userLocation | null>(null)
    const [loadingLocationData, setLoadingLocationData] = React.useState(true)
    const [loadingSmsLocationData, setLoadingSmsLocationData] = React.useState(false)
    const [loadingDelete, setLoadingDelete] = React.useState(false)
    const thisCountryData =
        locationData?.country_code &&
        countryData[locationData.country_code.toString().toUpperCase()]
    const apiFunctions = getUserTypeFunctions(ComplianceReportsTypes.LLUser)
    const { session } = useSessionIfExists()
    const { openAlert, AlertComponent } = createAlertSnackbar('success', 1.5)
    const { openModal, Component: SmsCompnent } = createSmsLocationHelperDialog(
        props.params.locationId,
        async (phoneNumber: string) => {
            await loadLocation()
            setLoadingSmsLocationData(false)
        }
    )

    async function loadLocation() {
        setLoadingLocationData(true)
        if (!props.params.locationId) return
        const response = await httpGet<userLocation>(`/locations/${props.params.locationId}`)
        setLocationData(response.data || null)
        setLoadingLocationData(false)
    }

    async function removeImage(uidToDelete: string) {
        if (!session?.user?.user_id) return
        setLoadingDelete(true)
        await apiFunctions.deleteDocumentation(session.user.user_id, uidToDelete)
        await loadLocation()
        setLoadingDelete(false)
    }

    function progressRender(sx?: any) {
        const csx = sx || {}
        return (
            <Box>
                <CircularProgress sx={{ m: 'auto', ...csx }} />
            </Box>
        )
    }

    function requestLocationForSMS() {
        setLoadingSmsLocationData(true)
        getLocation(
            (errorMessage: string) => {
                openAlert(errorMessage, 'error')
                setLoadingSmsLocationData(false)
            },
            (position) => {
                const deviceResult = getDeviceInfo()
                const currentLocation = {
                    ...position,
                    browser_name: deviceResult.name,
                    browser_version: deviceResult.version,
                    user_agent: deviceResult.userAgent,
                    os_name: deviceResult.os,
                    os_version: deviceResult.osVersion,
                    product: deviceResult.product,
                    manufacturer: deviceResult.manufacturer,
                    layout: deviceResult.layout,
                    description: deviceResult.description,
                }
                const smsParams = {
                    // ...currentLocation,
                    user_id: session?.user?.user_id,
                    location_id: props.params.locationId,
                }
                //                 const smsMessage = `test-45606 GeoSnapshot ll33a.dk
                // """${JSON.stringify(smsParams, null, 2)}"""
                // remove the test keyword as we got this new number with wildcard
                const smsMessage = `GeoSnapshot ll33a.dk
"""${JSON.stringify(smsParams, null, 2)}"""
`
                // const urlToOpen = `sms:&addresses=+4591544000&body=${smsMessage}`
                // window.open(urlToOpen)
                // setLoadingSmsLocationData(false)
                openModal(smsMessage)
            }
        )
    }

    React.useEffect(() => {
        loadLocation()
    }, [props.params.locationId])

    return (
        <AppPageWraper disableNavigation disableGeoSnapShooter>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {AlertComponent}
                {SmsCompnent}
                <Typography variant="h6" textAlign="center" gutterBottom>
                    Geo Snapshot
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '20px',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '12px',
                        }}
                    >
                        <Icon>{thisCountryData?.emoji}</Icon>
                        <Typography sx={{ mt: 1 }}>{thisCountryData?.name}</Typography>
                    </Box>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: '2px', width: '100%' }}
                    >
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography fontWeight="bold">
                                {dayjs(locationData?.snapshot_timestamp).format(
                                    'YYYY-MM-DD  HH:mm:ssZ'
                                )}
                            </Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography>Lokation: {locationData?.location_name}</Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography variant="body2">
                                Koordinater: {JSON.stringify(locationData?.coordinates, null, 2)}
                            </Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography variant="body2">
                                IP Adresse: {locationData?.ip_address}
                            </Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography variant="body2">
                                SMS Verikation:{' '}
                                {locationData?.sender_phone_number
                                    ? `Ja med tlf nr: +${locationData?.sender_phone_number}`
                                    : 'Nej'}
                            </Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid black', width: '100%' }}>
                            <Typography variant="body2">
                                OS: {locationData?.os_name || 'ikke tilgængelig'}, version:{' '}
                                {locationData?.os_version || 'ikke tilgængelig'}
                                <br />
                                Browser: {locationData?.browser_name || 'ikke tilgængelig'},
                                version: {locationData?.browser_version || 'ikke tilgængelig'}
                                <br />
                                <br />
                                User Agent: {locationData?.user_agent}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Grid sx={{ my: 2, width: '100%', gap: '2px' }} container>
                    {locationData?.documents?.map((document) => {
                        return (
                            <Grid item xs={4}>
                                <Box
                                    sx={{
                                        p: '2px',
                                        paddingLeft: '2px',
                                        paddingTop: '2px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                        border: '2px solid gray',
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            // maxHeight: 235,
                                            maxWidth: '85%',
                                            margin: 'auto',
                                            marginTop: '20px',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        badgeContent={
                                            loadingDelete ? (
                                                progressRender({ fontSize: 'large' })
                                            ) : (
                                                <IconButton
                                                    onClick={() => removeImage(document.uid)}
                                                >
                                                    <HighlightOffIcon
                                                        fontSize="large"
                                                        color="error"
                                                    />
                                                </IconButton>
                                            )
                                        }
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: '-20px',
                                                alignItems: 'center',
                                                width: 'min(150px, calc(min(100vw, 600px)/4))',
                                                height: 'min(150px, calc(100vw/4))',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth:
                                                        'min(150px, calc(min(100vw, 600px)/4))',
                                                    maxHeight: 'min(150px, calc(100vw/4))',
                                                    objectFit: 'contain',
                                                }}
                                                src={document.url}
                                            />
                                        </Box>
                                    </Badge>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 2,
                        px: 1,
                        gap: '5px',
                    }}
                >
                    <DynamicLoadingButton
                        size="small"
                        disabled={(locationData?.documents?.length || 0) > 0}
                        loading={loadingLocationData}
                        sx={{ margin: null, my: null, width: '100%' }}
                        onClick={() => {
                            navigate('add-photo')
                        }}
                    >
                        Tilføj billede
                    </DynamicLoadingButton>
                    <DynamicLoadingButton
                        disabled={locationData?.sender_phone_number != null}
                        loading={loadingSmsLocationData || loadingLocationData}
                        onClick={requestLocationForSMS}
                        size="small"
                        sx={{ margin: null, my: null, width: '100%' }}
                    >
                        Tilføj SMS verifikation
                    </DynamicLoadingButton>
                    <DynamicLoadingButton
                        sx={{ margin: null, my: null, mt: 1, width: '100%' }}
                        className="autotest-savenexit-button"
                        variant="contained"
                        color="success_color"
                        size="small"
                        href="/app"
                        onClick={async () => {
                            await httpPost(`/locations/${props.params.locationId}/statements`, {})
                            navigate('/app')
                        }}
                    >
                        Gem & afslut
                    </DynamicLoadingButton>
                    <DynamicLoadingButton
                        sx={{ margin: null, my: null, width: '100%' }}
                        variant="outlined"
                        color="success_color"
                        size="small"
                        href={`/app?calendar_day=${dayjs(locationData?.snapshot_timestamp).format(
                            'YYYY-MM-DD'
                        )}`}
                    >
                        Afslut uden PDF
                    </DynamicLoadingButton>
                </Box>
            </Box>
        </AppPageWraper>
    )
}
