import { Box, Button, Divider } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { AppHeader } from '../AppHeader'
import Pagewrapper from '../PageWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BackNavTop from './BackNavTop'

function AppPageWraper({
    children,
    disableNavigation = false,
    disableGeoSnapShooter = false,
}: any) {
    const { session } = useAuth('')

    return (
        <Pagewrapper>
            <AppHeader
                disableGeoSnapShooter={disableGeoSnapShooter}
                isBackgroundLoading={false}
                headerText={session?.user?.name ? session.user.name.split(' ')[0] : ''}
                userLocationDates={[]}
                setUserLocationDates={() => {}}
            />
            <Box sx={{ width: 'min(100%, 600px)', m: 'auto' }}>
                {!disableNavigation && <BackNavTop />}
                <Box sx={disableNavigation ? {} : { mt: '40px' }}>{children}</Box>
            </Box>
        </Pagewrapper>
    )
}

export default AppPageWraper
