import * as React from 'react'
import createAlertSnackbar from './snackbars'
import createActionDialog from './actionDialog'
import { Box, Stack, Typography } from '@mui/material'
import DynamicLoadingButton from '../input/DynamicLoadingButton'
import { httpGet, httpPost } from '../../services/http'
import { useSessionIfExists } from '../../hooks/useAuth'
import { getDeviceInfo } from '../../utils/deviceInfo'

export function createSmsLocationHelperDialog(
    locationId: string,
    onSmsVerified: (i: any) => Promise<void>
) {
    const [senderPhone, setSenderPhone] = React.useState<string | null>(null)
    const [messageToVerify, setMessageToVerify] = React.useState<string | null>(null)
    const [initiated, setInitiated] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [timerId, setTimerId] = React.useState<any>(null)
    const [isChecking, setIsChecking] = React.useState(false)
    const [deviceInfo, setDeviceInfo] = React.useState<any>(null)

    const { session } = useSessionIfExists()
    const { openAlert, AlertComponent } = createAlertSnackbar('success', 2)

    React.useEffect(() => {
        setDeviceInfo(getDeviceInfo())
    }, [])

    async function checkSnapshotWasVerified() {
        if (isChecking || !loading) return
        setIsChecking(true)
        const response = await httpGet<any>(`/locations/${locationId}`)
        if (response?.data?.sender_phone_number != null) {
            setSenderPhone(response?.data?.sender_phone_number)
            onSmsVerified(response?.data?.sender_phone_number)
            setLoading(false)
            onClose()
        }
        setIsChecking(false)
    }

    React.useEffect(() => {
        if (senderPhone != null) {
            clearInterval(timerId)
        }
    }, [senderPhone])

    React.useEffect(() => {
        if (!initiated || !!timerId) return
        const intervalId = setInterval(checkSnapshotWasVerified, 2000)
        setTimerId(intervalId)
        return () => {
            clearInterval(timerId)
            clearInterval(intervalId)
            setTimerId(null)
        }
    }, [initiated])

    function open(message: string) {
        openDialog()
        setMessageToVerify(message)
        setLoading(false)
    }

    function initiate() {
        if (!messageToVerify) return
        setLoading(true)
        setInitiated(true)
        const userAgent = getDeviceInfo().userAgent
        const isiOS = /(iPad|iPhone|iPod|Macintosh)/g.test(userAgent)
        let didOpenIOS = false
        let didOpenAndroid = false
        if (isiOS) {
            const urlToOpenIos = `sms:&addresses=004593704571&body=${messageToVerify}`
            didOpenIOS = !!window.open(urlToOpenIos)
        } else {
            const urlToOpenAndroid = `sms:004593704571?body=${messageToVerify}`
            didOpenAndroid = !!window.open(urlToOpenAndroid)
        }
        httpPost(
            `/actions/status/did-open-sms?isiOS=${isiOS}&didOpenIOS=${didOpenIOS}&didOpenAndroid=${didOpenAndroid}`
        )
    }

    function copyMessage() {
        if (!messageToVerify) return
        navigator.clipboard.writeText(messageToVerify)
        openAlert('Kopierede besked!')
    }

    async function handleSharing() {
        if (!messageToVerify) return
        setLoading(true)
        setInitiated(true)
        copyMessage()
    }

    function onClose() {
        closeDialog()
        clearInterval(timerId)
        setTimerId(null)
    }

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography variant="h6" textAlign="center">
                    SMS verifikation
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {loading && deviceInfo?.os === 'iOS' && (
                        <Typography color="black" fontSize="18px" sx={{ my: 1 }}>
                            Problemer med at sende beskeden? Prøv evt dette link:{' '}
                            <a href="https://support.apple.com/da-dk/HT204065" target="_blank">
                                https://support.apple.com/da-dk/HT204065
                            </a>
                        </Typography>
                    )}
                    <Typography fontSize="16px">
                        For at verificere dit GeoSnapshot med SMS skal du sende følgende besked,
                        <br />
                        SMS til 004593704571:
                    </Typography>
                    <Typography fontSize="12px">{messageToVerify}</Typography>
                </Box>
                <DynamicLoadingButton
                    loading={loading}
                    onClick={initiate}
                    href={`sms:&addresses=004593704571&body=${messageToVerify}`}
                >
                    Åbn besked app
                </DynamicLoadingButton>
                <DynamicLoadingButton variant="outlined" loading={loading} onClick={handleSharing}>
                    Kopier besked
                </DynamicLoadingButton>
                <DynamicLoadingButton
                    size="small"
                    sx={{ p: 1 }}
                    variant="outlined"
                    color="error"
                    onClick={async () => {
                        await onSmsVerified(null)
                        onClose()
                    }}
                >
                    Luk
                </DynamicLoadingButton>
            </Stack>
        </Box>
    )

    const { openDialog, closeDialog, DialogComponent } = createActionDialog(
        () => {},
        () => {},
        'Samtykke',
        content,
        undefined,
        undefined,
        'info',
        'info',
        true,
        true
    )

    return {
        Component: (
            <>
                {DialogComponent}
                {AlertComponent}
            </>
        ),
        openModal: open,
    }
}
