import { Box, Button, Divider } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { AppHeader } from '../AppHeader'
import Pagewrapper from '../PageWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function NavTop({
    top = '48px',
    width = 'min(100%, 600px)',
    backgroundColor = 'white',
    variant = 'text',
    navTo = -1,
}: any) {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: top,
                width: width,
                zIndex: 100,
                backgroundColor: backgroundColor,
            }}
        >
            <Box
                sx={{
                    height: '36.5px',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    top: top,
                    width: '100%',
                    zIndex: 100,
                }}
            >
                <Button
                    variant={variant}
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(navTo)}
                >
                    Gå Tilbage
                </Button>
            </Box>
            <Divider sx={{ mb: 1, mt: '36.5px' }} />
        </Box>
    )
}
